import { datadogRum } from '@datadog/browser-rum';

let APP_VERSION = document.querySelector("meta[name='app-version']");
let ENV = document.querySelector("meta[name='env']");

if(APP_VERSION != null && ENV != null) {
  datadogRum.init({
    applicationId: '9bfd33f8-76db-44eb-9219-e6b3e4009fa4',
    clientToken: 'pub4f3a6906b1f0af7c6b544f512e5224cf',
    site: 'datadoghq.com',
    service: 'titan',
    env: ENV.getAttribute('content'),
    version: APP_VERSION.getAttribute('content'),
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: false,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    useSecureSessionCookie: true
  });

  datadogRum.startSessionReplayRecording();
}
